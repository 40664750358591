import { request } from '@@/plugin-request';

export async function getTeacherReview(params) {
  return request('/api/users/teacherReview', {
    method: 'get',
    params,
  });
}

export async function createTeacherGrade(data) {
  return request(`/api/teacherGrade`, {
    method: 'post',
    data,
  });
}
