import { NotificationOutlined } from '@ant-design/icons';
import { ProList } from '@ant-design/pro-components';
import { Badge, Button, Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useModel } from '@umijs/max';
import NoticeContent from './NoticeContent';
import dayjs from '@/utils/dayjs';
import { getUsername } from '@/utils/utils';

const Notifications = () => {
  const [activeKey, setActiveKey] = useState('unread');
  const [dataSource, setDataSource] = useState([]);
  const noticeModel = useModel('notification');
  const {
    notifications,
    loading,
    fetchNotifications,
    toggleReadStatus,
    noticeCenterOpen,
    setNoticeCenterOpen,
    isClosedByUser,
    setIsClosedByUser,
    unreadCount,
    lastUnreadNumber,
    setLastUnreadNumber,
  } = noticeModel;

  const [notifyApi, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (!loading) {
      const data = notifications.filter((el) => {
        if (activeKey === 'unread') {
          return !el.read;
        }
        if (activeKey === 'read') {
          return el.read;
        }
        if (activeKey === 'all') {
          return true;
        }
      });
      setDataSource(
        data.map((item) => ({
          id: item.id,
          name: item.title,
          content: item.content,
          desc: `${getUsername(item.createdBy)} 发布于${dayjs(item.createdAt).format(
            'YYYY-MM-DD HH:mm',
          )} • 有效期 ${dayjs(item.validFrom).format('YYYY-MM-DD HH:mm')} 至 ${dayjs(item.expireAt).format('YYYY-MM-DD HH:mm')}`,
          unread: !item.read,
        })),
      );
    }
  }, [loading, activeKey, notifications]);

  useEffect(() => {
    if (
      !loading &&
      unreadCount > 0 &&
      !noticeCenterOpen &&
      (!isClosedByUser || unreadCount > lastUnreadNumber)
    ) {
      notifyApi.info({
        key: 'notify',
        duration: 0,
        message: `您有${unreadCount}条未读通知`,
        description: (
          <Button
            style={{
              padding: 0,
            }}
            type="link"
            onClick={() => {
              setLastUnreadNumber(0);
              setIsClosedByUser(false);
              setNoticeCenterOpen(true);
              notifyApi.destroy('notify');
            }}
          >
            点击查看
          </Button>
        ),
        onClose: () => {
          setIsClosedByUser(true);
          setLastUnreadNumber(unreadCount);
        },
      });
    }
  }, [loading, unreadCount, noticeCenterOpen, isClosedByUser, lastUnreadNumber]);

  const renderBadge = (count, active = false) => {
    return (
      <Badge
        count={count}
        style={{
          marginBlockStart: -2,
          marginInlineStart: 4,
          color: active ? '#1890FF' : '#999',
          backgroundColor: active ? '#E6F7FF' : '#eee',
        }}
      />
    );
  };

  return (
    <div>
      {contextHolder}
      <Badge count={unreadCount}>
        <Button
          icon={<NotificationOutlined />}
          onClick={() => {
            setNoticeCenterOpen(true);
          }}
        />
      </Badge>
      <Modal
        style={{
          minWidth: '75%',
        }}
        open={noticeCenterOpen}
        onCancel={() => {
          setNoticeCenterOpen(false);
        }}
        footer={null}
      >
        <div style={{ maxHeight: '800px', overflowY: 'auto' }}>
          {/* Set maximum height and scrolling */}
          <ProList
            loading={loading}
            size="large"
            rowKey="name"
            pagination={{
              pageSize: 5, // Number of items per page
              position: 'bottom', // Optional: Position of the pagination component
              size: 'small',
            }}
            dataSource={dataSource}
            metas={{
              title: {
                dataIndex: 'name',
                render: (text) => {
                  return (
                    <div>
                      <div style={{ fontSize: '20px', cursor: 'default' }}>{text}</div>
                    </div>
                  );
                },
              },
              description: {
                dataIndex: 'content',
                render: (_, record) => {
                  return (
                    <div style={{ margin: '10px', padding: '10px' }}>
                      <NoticeContent content={record.content} refresh={fetchNotifications} />
                      <div style={{ whiteSpace: 'pre' }}>{record.desc}</div>
                    </div>
                  );
                },
              },
              actions: {
                render: (text, row) => [
                  row.unread ? (
                    <a
                      href={row.html_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      key="link"
                      onClick={() => {
                        toggleReadStatus(row.id, true);
                      }}
                    >
                      标记为已读
                    </a>
                  ) : (
                    <a
                      href={row.html_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      key="link"
                      onClick={() => {
                        toggleReadStatus(row.id, false);
                      }}
                    >
                      标记为未读
                    </a>
                  ),
                ],
              },
            }}
            toolbar={{
              menu: {
                activeKey,
                items: [
                  {
                    key: 'unread',
                    label: (
                      <span>
                        未读消息
                        {renderBadge(unreadCount, activeKey === 'unread')}
                      </span>
                    ),
                  },
                  {
                    key: 'read',
                    label: <span>已读消息</span>,
                  },
                  {
                    key: 'all',
                    label: <span>全部消息</span>,
                  },
                ],
                onChange(key) {
                  setActiveKey(key);
                },
              },
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Notifications;
