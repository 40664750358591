import component from './zh-CN/component';
import dashboard from './zh-CN/dashboard';
import globalHeader from './zh-CN/globalHeader';
import menu from './zh-CN/menu';
import pwa from './zh-CN/pwa';
import settingDrawer from './zh-CN/settingDrawer';
import settings from './zh-CN/settings';
import userProfile from './zh-CN/userProfile';

export default {
  lastName: '姓',
  firstName: '名',
  chineseName: '中文名',
  nickname: '昵称',
  username: '用户名',
  password: '密码',
  gender: '性别',
  male: '男',
  female: '女',
  nationality: '国籍',
  mobile: '手机号',
  areaCode: '地区号',
  phone: '电话',
  wechat: '微信',
  wechatId: '微信号',
  email: '邮箱',
  role: '角色',
  create: '创建',
  update: '更新',
  submit: '提交',
  cancel: '取消',
  today: '今天',
  inProgress: '正在进行',
  countdownMinutes: '{minutes}分钟后开始',
  start: '开始',
  copyInvitation: '复制会议邀请',
  ended: '已结束',
  'app.welcome.link.block-list': '基于 block 开发，快速构建标准页面',
  'navBar.lang': '语言',
  'layout.user.link.help': '帮助',
  'layout.user.link.privacy': '隐私',
  'layout.user.link.terms': '条款',
  'app.preview.down.block': '下载此页面到本地项目',
  'app.welcome.link.fetch-blocks': '获取全部区块',
  'app.welcome.link.block-list': '基于 block 开发，快速构建标准页面',
  ...globalHeader,
  ...menu,
  ...settingDrawer,
  ...settings,
  ...pwa,
  ...component,
  ...userProfile,
  ...dashboard,
};
