import { get4User, updateReadStatus } from '@/components/Notice/service';
import { isAxiosUnauthorizedError } from '@/utils/errors';
import { message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useModel } from 'umi';

export default function useNotificationModel() {
  const { currentUser, initialStateLoading, setInitialState } = useModel(
    '@@initialState',
    (model) => ({
      currentUser: model.initialState?.currentUser,
      initialStateLoading: model.loading,
      setInitialState: model.setInitialState,
    }),
  );

  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [intervalId, setIntervalId] = useState(null);
  const [isClosedByUser, setIsClosedByUser] = useState(false);
  const [lastUnreadNumber, setLastUnreadNumber] = useState(0);
  const [noticeCenterOpen, setNoticeCenterOpen] = useState(false);

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const data = await get4User();
      setNotifications(data);
    } catch (error) {
      if (isAxiosUnauthorizedError(error)) {
        setInitialState((prev) => ({
          ...prev,
          currentUser: {
            ...currentUser,
            logout: true,
          },
        }));
        if (intervalId) {
          clearInterval(intervalId);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!initialStateLoading && currentUser?.id && !intervalId && !currentUser.logout) {
      const intervalId = setInterval(() => {
        fetchNotifications();
      }, 300 * 1000); // get new notice every 300 seconds
      setIntervalId(intervalId);
      fetchNotifications();
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [initialStateLoading, intervalId, currentUser]);

  const toggleReadStatus = useCallback(async (id, markAsRead) => {
    await updateReadStatus({ id, markAsRead })
      .then(async (res) => {
        await fetchNotifications();
        message.success('更新已读状态成功');
      })
      .catch((err) => {
        message.error('更新已读状态失败');
      });
  }, []);

  return {
    notifications,
    loading,
    fetchNotifications,
    toggleReadStatus,
    noticeCenterOpen,
    setNoticeCenterOpen,
    isClosedByUser,
    setIsClosedByUser,
    unreadCount: (notifications || []).filter((el) => !el.read).length,
    lastUnreadNumber,
    setLastUnreadNumber,
  };
}
