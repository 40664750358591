import { Input, InputNumber, Space } from 'antd';
import { useState, useEffect } from 'react';

const getInitValue = (type, value) => {
  if (type === 'minutes') {
    if (value?.minutes) {
      return Number(value.minutes);
    }
    if (typeof value === 'number') {
      const h = Math.floor(value / 60);
      const m = value - h * 60;
      return m;
    }
    return 0;
  }
  if (type === 'hours') {
    if (value?.hours) {
      return Number(value.hours);
    }
    if (typeof value === 'number') {
      const h = Math.floor(value / 60);
      return h;
    }
    return 0;
  }
  return 0;
};

const RawDurationView = ({ value, onChange, disabled = false, step = 1, ...rest }) => {
  const [hours, setHours] = useState(getInitValue('hours', value));
  const [minutes, setMinutes] = useState(getInitValue('minutes', value));

  useEffect(() => {
    setHours(getInitValue('hours', value));
    setMinutes(getInitValue('minutes', value));
  }, [value]);

  // const onHourChange = (hours) => {
  //   const minutes = getInitValue('minutes', value);
  //   const duration = (hours || 0) * 60 + (minutes || 0);
  //   onChange(duration);
  // };

  // const onMinutesChange = (minutes) => {
  //   const hours = getInitValue('hours', value);
  //   const duration = (hours || 0) * 60 + (minutes || 0);
  //   onChange(duration);
  // };

  const onHourChange = (newHours) => {
    setHours(newHours);
    const duration = (newHours || 0) * 60 + (minutes || 0);
    onChange(duration);
  };

  const onMinutesChange = (newMinutes) => {
    if (newMinutes === undefined || newMinutes === null) {
      setMinutes(0);
      return;
    }
    let totalMinutes = (hours || 0) * 60 + newMinutes;
    const normalizedHours = Math.floor(totalMinutes / 60);
    const normalizedMinutes = totalMinutes % 60;
    setHours(normalizedHours);
    setMinutes(normalizedMinutes);
    const duration = normalizedHours * 60 + normalizedMinutes;
    onChange(duration);
  };

  return (
    <Space.Compact>
      <InputNumber
        disabled={disabled}
        min={0}
        // value={getInitValue('hours', value)}
        value={hours}
        onChange={onHourChange}
      />
      <span style={{ marginLeft: 5, marginRight: 15 }}>小时</span>
      <InputNumber
        disabled={disabled}
        min={0}
        step={step}
        // value={getInitValue('minutes', value)}
        value={minutes}
        onChange={onMinutesChange}
        formatter={(value) => String(value)}
        parser={(value) => {
          const parsedValue = parseInt(value || '0', 10);
          return isNaN(parsedValue) ? 0 : parsedValue;
        }}
      />
      <span style={{ marginLeft: 5 }}>分钟</span>
    </Space.Compact>
  );
};

export default RawDurationView;
